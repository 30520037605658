import './style/Assessment.css';
import React, { useState, useEffect } from 'react';

import Table from './Table';
import FileUpload from './FileUpload';
import Urgency from './Urgency';
import ColumnSelect from './ColumnSelect';
import Stepper from './Stepper';
import logo from '../media/logo-materialize.svg';
import Check from './icon/Check';
import { getValueForRow, formatToCurrency } from '../util/Calculate';
import b9 from '../media/sd-sample/b9.jpeg';
import samsung from '../media/sd-sample/samsung-mz-v81pto.webp';
import carbon from '../media/sd-sample/carbon.png';
import axiomatic from '../media/sd-sample/axiomatic.png';
import fso from '../media/sd-sample/fso.png';
import antenna from '../media/sd-sample/antenna.jpeg';
import antennaTwo from '../media/sd-sample/antenna-two.jpeg';
import eaton from '../media/sd-sample/eaton.jpeg';
import torqueedo from '../media/sd-sample/torqueedo.jpeg';
import apc from '../media/sd-sample/apc.png';
import raritan from '../media/sd-sample/raritan.png';
import etherwan from '../media/sd-sample/etherwan.png';

const Assessment = ({pdfView, login, sessionId, userEmail, accessToken, setGoToDashboard}) => {
    const [step, setStep] = useState(0);
    const [data, setData] = useState([]);
    const [numberColumns, setNumberColumns] = useState([]);
    const [selectedCostColumn, setSelectedCostColumn] = useState('manual-cost');
    const [selectedQtyColumn, setSelectedQtyColumn] = useState('manual-qty');
    const [urgency, setUrgency] = useState(1);
    const [total, setTotal] = useState('');
    const [costType, setCostType] = useState('total');
    const [enabledSteps, setEnabledSteps] = useState([0]);
    const [rawTotal, setRawTotal] = useState(0);
    const [rawCost, setRawCost] = useState(0);
    const [actionsLeft, setActionsLeft] = useState(0);
    const [savedFileId, setSavedFileId] = useState(null);
    const [savedAssessmentId, setSavedAssessmentId] = useState(null);
    const [otsCotsCostTotal, setOtsCotsCostTotal] = useState(0);
    const [customCostTotal, setCustomCostTotal] = useState(0);
    const [submitAssessmentError, setSubmitAssessmentError] = useState(false);
    const [assessmentSubmitted, setAssessmentSubmitted] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [manualEmail, setManualEmail] = useState('');
    const [deletedCotsOtsColumns, setDeletedCotsOtsColumns] = useState([]);
    const [deletedCustomColumns, setDeletedCustomColumns] = useState([]);
    const [highUrgencyTotal, setHighUrgencyTotal] = useState('');
    const [moderateUrgencyTotal, setModerateUrgencyTotal] = useState('');
    const [lowUrgencyTotal, setLowUrgencyTotal] = useState('');
    const [date, setDate] = useState('');
    const [rawOtsCotsCostTotal, setRawOtsCotsCostTotal] = useState(0);
    const [rawCustomCostTotal, setRawCustomCostTotal] = useState(0);
    const [submittingAssessment, setSubmittingAssessment] = useState(false);
    const [totalBeforeFee, setTotalBeforeFee] = useState('');
    const [totalFee, setTotalFee] = useState('');

    useEffect(() => {
        // set formatted date of MM/DD/YYYY
        const today = new Date();
        let month = today.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;

        const day = today.getDate();
        const year = today.getFullYear();
        setDate(`${month}/${day}/${year}`);
    }, []);

    useEffect(() => {
        let total = 0;
        data.forEach(row => {
            if (!row['Minimum Price']) return;
            total += Number(row['Minimum Price']?.replace(/\$|,/g, ''));
        });
        setTotal(formatToCurrency(total * .8));
        setTotalBeforeFee(formatToCurrency(total));
        setTotalFee(formatToCurrency(total * .2));

        let newHighUrgencyTotal = 0;
        let newModerateUrgencyTotal = 0;
        let newLowUrgencyTotal = 0;
        data.forEach(row => {
            total += Number(row['Minimum Price']?.replace(/\$|,/g, ''));
            newHighUrgencyTotal += getValueForRow(row, selectedCostColumn, selectedQtyColumn, costType, 0);
            newModerateUrgencyTotal += getValueForRow(row, selectedCostColumn, selectedQtyColumn, costType, 1);
            newLowUrgencyTotal += getValueForRow(row, selectedCostColumn, selectedQtyColumn, costType, 2);
        });

        setHighUrgencyTotal(newHighUrgencyTotal);
        setModerateUrgencyTotal(newModerateUrgencyTotal);
        setLowUrgencyTotal(newLowUrgencyTotal);
    }, [data]);

    useEffect(() => {
        if(enabledSteps.includes(step)) return;
        setEnabledSteps(steps => [...steps, step]);
    }, [step]);
    
    const analyzeDataForDollarValues = (data) => {
        const numberCols = [];
        data.forEach(row => {
            Object.keys(row).forEach(key => {
                const value = row[key]?.toString().trim();
                const numberRegex = /^\$?\s*\d{1,3}(,\d{3})*(\.\d{1,2})?$/;
                
                if (value?.match(numberRegex) && !numberCols.includes(key)) {
                    numberCols.push(key);
                }
            });
        });
        setNumberColumns(numberCols);
    };

    const getFormattedAssessmentData = () => {
        return data.map(row => {
            const keysToSkip = ["Minimum Price", "manual-cost", "manual-qty", "manual-type", "manual-condition", "manual-material", "manual-weight", "minimum-return"];

            let persistedItem = {
                cost: selectedCostColumn === 'manual-cost' ? Number(row['manual-cost']) : Number(row[selectedCostColumn]?.replace(/\$|,/g, '')),
                cost_type: costType,
                quantity: (costType === 'unit' && selectedQtyColumn === 'manual-qty') ? row['manual-qty'] : costType === 'unit' ? row[selectedQtyColumn] : null,
                type: row['manual-type'],
                condition: row['manual-condition'].toLowerCase(),
                material: row['manual-material'],
                weight: Number(row['manual-weight']),
                urgency: urgency === 0 ? "high" : urgency === 1 ? "moderate" : "low",
                minimum_return: Number((row["Minimum Price"] ? row["Minimum Price"] : "")?.replace(/\$|,/g, '')),
                raw_fields: {}
            };

            if (row['manual-type'] === 'custom') {
                persistedItem.cost = null;
                persistedItem.cost_type = null;
                persistedItem.quantity = null;
                persistedItem.condition = null;
            } else {
                persistedItem.material = null;
                persistedItem.weight = null;
            }

            for (const [key, value] of Object.entries(row)) {
                if (keysToSkip.includes(key)) continue;
                persistedItem.raw_fields[key] = value;
            }

            return persistedItem;
        });
    };

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const submitAssessment = async () => {
        // get value from email input
        const email = document.getElementById('email').value;
        if (!isValidEmail(email)) {
            setEmailValid(false);
            return;
        } else {
            setEmailValid(true);
        }

        setSubmittingAssessment(true);

        const assessmentData = getFormattedAssessmentData();
        const assessment = {
            line_items: assessmentData,
            email,
            selected_cost_column: selectedCostColumn === 'manual-cost' ? null : selectedCostColumn,
            selected_qty_column: selectedQtyColumn === 'manual-qty' ? null : selectedQtyColumn,
            deleted_cots_ots_columns: deletedCotsOtsColumns,
            deleted_custom_columns: deletedCustomColumns
        };

        try {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(assessment),
            }

            if (accessToken) {
                options.headers.Authorization = `Bearer ${accessToken}`;    
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}/assessment/${savedAssessmentId}/submit`, options);

            if (response.status !== 200) {
                setSubmitAssessmentError(true);
                setSubmittingAssessment(false);
            } else {
                await response.json();
                setSubmitAssessmentError(false);
                setAssessmentSubmitted(true);
                setSubmittingAssessment(false);
                setGoToDashboard(true);
            }
        } catch (error) {
            // console.log(error);
            setSubmitAssessmentError(true);
            setSubmittingAssessment(false);
        };
    };

    const handleEmailChange = (e) => {
        setManualEmail(e.target.value);
    };
    
    const considerations = [
        {
            item: "Baldwin Filter (B9)",
            reasons: [
                "This price ensures competitive edge in a market where similar items are listed below $10. This price undercuts the lowest price in the market, attracting buyers looking for deals.",
            ],
            image: b9,
        }, {
            item: "Samsung SSD (MZ-V8P1TO)",
            reasons: [
                "Given this item is a modern component applicable to a broad variety of computing use cases, this price reflects quantity available and competes with the lowest average resale price.",
            ],
            image: samsung,
        }, {
            item: "Carbon Fiber Roll (400gsm 100mm x 100y)",
            reasons: [
                "This price reflects the quality of this material, the Non-Crimping attribute, its quantity, and the market availability of a direct alternative. It exceeds the stated cost while remaining competitive with previously available prices for this exact material.",
            ],
            image: carbon,
        }, {
            item: "Axiomatic RTD Scanner (AX184000)",
            reasons: [
                "This item is old (2018) and no longer eligible for RMA due to out-of-date firmware. There are no comparable items on secondary markets, and demand for this particular part is low.",
            ],
            image: axiomatic,
        }, {
            item: "NAPA Fuel Shut-Off Solenoid (FSO301)",
            reasons: [
                "This is a part for an old (1994-1998) vehicle, reducing its overall demand. It is additionally sold by auto parts online stores, making a price decrease necessary for its resale.",
            ],
            image: fso,
        }, {
            item: "Tallysman GPS Antenna (33-1421-09-XXXX)",
            reasons: [
                "This is a somewhat older embedded single-band GNSS antenna that is sold by distributors new from the manufacturer. There is not a significant resale market, and given the quantity, a bulk re-purchase would be necessary to move these items within the specified urgency.",
            ],
            image: antenna,
        }, {
            item: "Tallysman GPS Antenna (33-1600-09-0050)",
            reasons: [
                "This is a somewhat older embedded iridium antenna that is sold by distributors new from the manufacturer. There is not a significant resale market, and given the quantity, a bulk re-purchase would be necessary to move these items within the specified urgency.",
            ],
            image: antennaTwo,
        }, {
            item: "Eaton Multiplex Vehicle Electrical Center (31M-330-0)",
            reasons: [
                "This item is modern and sold by distributors, which necessitates discount pricing to attract buyers.",
            ],
            image: eaton,
        }, {
            item: "Torqeedo Cruise 4.0RL Electric Outboard Motor (1233-00)",
            reasons: [
                "This is a modern and currently sold motor from Torqeedo, allowing a resale at only a small discount from MSRP. Additionally it is a somewhat special part, not listed for immediate purchase on resale, allowing its lack of lead time to improve the sale price.",
            ],
            image: torqueedo,
        }, {
            item: "APC Symmetra LX (SYA16K16RMP)",
            reasons: [
                "The market value for this battery significantly decreases when used, especially if it has not been refurbished before resale. A significant discount on the refurbished sale price is necessary to move this item.",
            ],
            image: apc,
        }, {
            item: "Raritan Dominion Switch (RADKX3108)",
            reasons: [
                "While this item is available for resale, it is at a significant discount and market demand does not seem to be present, necessitating a further discount.",
            ],
            image: raritan,
        }, {
            item: "Etherwan Switch (EX77964-8VT)",
            reasons: [
                "This item is not available for resale, but comparable items are. Market demand signals are low, necessitating a significant discount.",
            ],
            image: etherwan,
        }
    ];

    return (
        <div id="assessment" className={pdfView && "pdf-view"}>
            {pdfView && (
                <div className="horizontal full-width space-between top">
                    <div id="pdf-header" className="vertical">
                        <img src={logo} id="logo-pdf" alt="logo" />
                        <div id="pdf-address" className="horizontal">
                            <div className="vertical">
                                <p className="label-fixed">Address:</p>
                            </div>
                            <div className="vertical">
                                <p>1288 McAllister St, #302</p>
                                <p>San Francisco, CA 94115</p>
                            </div>
                        </div>
                        <div id="pdf-address" className="horizontal">
                            <div className="vertical">
                                <p className="label-fixed">Contact:</p>
                            </div>
                            <div className="vertical">
                                <p>evan@trymaterialize.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="vertical">
                        {pdfView && <p className="pdf-date">#M240001</p>}
                        {pdfView && <p className="pdf-date">{date}</p>}
                    </div>
                </div>
            )}
            {!pdfView && <Stepper step={step} setStep={setStep} enabledSteps={enabledSteps} />}
            <div id="assessment-header-wrapper">
                <FileUpload step={step} analyzeDataForDollarValues={analyzeDataForDollarValues} setData={setData} setStep={setStep} setEnabledSteps={setEnabledSteps} sessionId={sessionId} setSavedFileId={setSavedFileId} setSavedAssessmentId={setSavedAssessmentId}/>
                {step === 1 &&
                    <ColumnSelect
                        costType={costType}
                        setCostType={setCostType}
                        data={data}
                        setData={setData}
                        setStep={setStep}
                        numberColumns={numberColumns} 
                        selectedCostColumn={selectedCostColumn} 
                        setSelectedCostColumn={setSelectedCostColumn}
                        selectedQtyColumn={selectedQtyColumn}
                        setSelectedQtyColumn={setSelectedQtyColumn} />
                }
            </div>
            {step === 2 &&
                <div className="vertical mw-90">
                    <div className="horizontal full-width space-between">
                        <h2 className="title">{pdfView ? "Inventory Assessment" : "Review Assessment"}</h2>
                        {!pdfView && <Urgency urgency={urgency} setUrgency={setUrgency} />}
                    </div>
                    {pdfView && <Urgency urgency={urgency} setUrgency={setUrgency} pdfView={pdfView} rawTotal={rawTotal} highUrgencyTotal={highUrgencyTotal} moderateUrgencyTotal={moderateUrgencyTotal} lowUrgencyTotal={lowUrgencyTotal} />}
                    <Table
                        data={data}
                        setData={setData}
                        numberColumns={numberColumns}
                        selectedCostColumn={selectedCostColumn}
                        selectedQtyColumn={selectedQtyColumn}
                        urgency={urgency}
                        setTotal={setTotal}
                        costType={costType}
                        pdfView={pdfView}
                        setActionsLeft={setActionsLeft}
                        setRawCost={setRawCost}
                        setRawTotal={setRawTotal} 
                        otsCotsCostTotal={otsCotsCostTotal}
                        setOtsCotsCostTotal={setOtsCotsCostTotal}
                        customCostTotal={customCostTotal}
                        setCustomCostTotal={setCustomCostTotal}
                        setDeletedCotsOtsColumns={setDeletedCotsOtsColumns}
                        deletedCotsOtsColumns={deletedCotsOtsColumns}
                        setDeletedCustomColumns={setDeletedCustomColumns}
                        deletedCustomColumns={deletedCustomColumns}
                        setRawOtsCotsCostTotal={setRawOtsCotsCostTotal}
                        setRawCustomCostTotal={setRawCustomCostTotal} />
                    {/* {pdfView && (
                        <div className="vertical">
                            <div id="pdf-total-wrapper" className="horizontal end">
                                <p id="pdf-total-label">Total Value:</p>
                                <p id="pdf-total">{total}</p>
                            </div>
                        </div>
                    )} */}
                    {pdfView && (
                        <div className="horizontal top m-b-64">
                            <div className="horizontal flex-1">
                            </div>
                            <div id="total-block" className="vertical end top">
                                {rawCustomCostTotal > 0 &&
                                    <div className="horizontal full-width space-between">
                                        <p className="sub-total-info">Value Captured:</p>
                                        <p className="label sub-total">{otsCotsCostTotal}</p>
                                    </div>
                                }
                                {rawCustomCostTotal > 0 &&
                                    <div className="horizontal full-width space-between">
                                        <p className="sub-total-info">Recycling Subtotal:</p>
                                        <p className="label sub-total">{rawCustomCostTotal}</p>
                                    </div>
                                }
                                {pdfView &&
                                    <div className="horizontal full-width space-between">
                                        <p className="sub-total-info">Subtotal:</p>
                                        <p className="label sub-total">{totalBeforeFee}</p>
                                    </div>
                                }
                                {pdfView &&
                                    <div className="horizontal full-width space-between">
                                        <p className="sub-total-info">Our Fee (20%):</p>
                                        <p className="label sub-total broker-amount">({totalFee})</p>
                                    </div>
                                }
                                <div id="total-block-contents" className={rawCustomCostTotal > 0 ? "horizontal top border-top" : "horizontal top border-top"}>
                                    <div className="vertical m-r-12">
                                        <div id="pdf-total-wrapper" className="vertical end">
                                            <p id="pdf-total-label">Your Value Captured:</p>
                                            <p id="disclaimer"><span className="bold">Note: </span>Reflects the amount you would receive if all items assessed above are sold.</p>
                                        </div>
                                    </div>
                                    <div className="horizontal end">
                                        <p id="pdf-total">{total}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                    {/* {pdfView && (
                        <div className="vertical">
                            <p>Conditions</p>
                        </div>
                    )} */}
                    {!pdfView && (
                        <div id="summary">
                            <div id="summary-card-style" className="vertical space-between">
                                {submittingAssessment && <div id="summary-loader-overlay">
                                    <div id="summary-loader" className="horizontal"><p>Submitting Assessment...</p></div>
                                </div>}
                                <div className="horizontal space-between full-width">
                                    <div className="vertical">
                                        <div id="approval-header-wrapper" className="horizontal gravity-left">
                                            <h3 id="approve-title" className={!assessmentSubmitted ? "title" : "title no-padding"}>{!assessmentSubmitted ? "Approve Assessment" : "Assessment Submitted"}</h3>
                                            {assessmentSubmitted && <Check size={22} />}
                                        </div>
                                        {assessmentSubmitted && <p className="label assessment-message success">Your assessment has been submitted. Our team will reach out shortly!</p>}
                                    </div>
                                    <div className="vertical">
                                        <p className="total-amount">{total}</p>
                                        <p id="total-label">Total Return</p>
                                    </div>
                                </div>
                                {!assessmentSubmitted && <div id="email-wrapper" className="horizontal bottom">
                                    <div id="email-input-wrapper" className="vertical left">
                                        <p className="input-label">Email</p>
                                        <input id="email" type="email" placeholder='name@website.com'value={userEmail || manualEmail} className={!emailValid && 'invalid'} onChange={(e) => handleEmailChange(e)} disabled={userEmail}/>
                                    </div>
                                    <button onClick={submitAssessment}>Submit</button>
                                </div>}
                                {submitAssessmentError && <p className="label assessment-message error">There was an error submitting your assessment. Please try again in a few moments, or contact <a className="error-link" href="mailto:info@trymaterialize.com">info@trymaterialize.com</a> if the problem persists.</p>}
                            </div>
                        </div>
                    )}
                    {pdfView && (
                        <div id="pdf-footer-two" className="vertical">
                            <div className="divider"></div>
                            <div className="vertical disclaimer-wrapper">
                                <p className="disclaimer">Materialize captures the most value from excess inventory for advanced manufacturing firms through strategic resale. We analyze each part thoroughly, considering market demand, supply, and urgency for sale to determine a Movable Price.</p>
                                <p className="disclaimer"><span className="bold">Key Considerations:</span> (1) Sale Assessment Disclaimer: While we carefully evaluate each item to determine a Movable Price, sales are not guaranteed. The actual sale of items and the total revenue generated are contingent upon market demand and buyer interest. Your final earnings depend on the successful resale of items. (2) Transaction Security: Materialize enhances security by implementing a 30-day hold on sales proceeds, covering returns and maintaining transaction integrity. (3) Indemnification: Buyers are fully responsible for their purchases, allowing partners to confidently sell surplus inventory.</p>
                            </div>
                        </div>
                    )}
                    {pdfView && (
                        <div id="consideration" className="page-break-avoid-wrapper">
                            <div className="vertical page-break-avoid-wrapper">
                                <p className="label">Considerations</p>
                                <div className="horizontal full-width top">
                                    <div className="vertical flex-1 p-r-8">
                                        <ol>
                                        {considerations.slice(0, (considerations.length + 1) / 2).map((consideration, index) => (
                                            <li>
                                                <div className="horizontal top page-break-avoid-wrapper">
                                                    <img className="consideration-image" src={consideration.image} />
                                                    <div className="vertical">
                                                        <p className="content-label">{consideration.item}</p>
                                                        <ul>
                                                            {consideration.reasons.map(reason => (
                                                                <li>
                                                                    <p className="content">{reason}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        {(consideration.sources && consideration.sources.length > 0) &&
                                                            <p className="content italics">References: {consideration.sources?.map((source, index) => (
                                                            <a href={source} target="_blank">Source {index + 1}</a>
                                                            ))}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        </ol>
                                    </div>
                                    <div className="vertical flex-1 p-l-8">
                                        <ol>
                                        {considerations.slice((considerations.length + 1) / 2, considerations.length + 1).map((consideration, index) => (
                                            <li>
                                                <div className="horizontal top page-break-avoid-wrapper">
                                                    <img className="consideration-image" src={consideration.image} />
                                                    <div className="vertical">
                                                        <p className="content-label">{consideration.item}</p>
                                                        <ul>
                                                            {consideration.reasons.map(reason => (
                                                                <li>
                                                                    <p className="content">{reason}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        {(consideration.sources && consideration.sources.length > 0) &&
                                                            <p className="content italics">References: {consideration.sources?.map((source, index) => (
                                                            <a href={source} target="_blank">Source {index + 1}</a>
                                                            ))}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {pdfView && (
                        <div id="pdf-footer" className="vertical">
                            <div className="divider"></div>
                            <div className="vertical disclaimer-wrapper">
                                <p className="disclaimer">Materialize captures the most value from excess inventory for advanced manufacturing firms through strategic resale. We analyze each part thoroughly, considering market demand, supply, and urgency for sale to determine a Movable Price.</p>
                                <p className="disclaimer"><span className="bold">Key Considerations:</span> (1) Sale Assessment Disclaimer: While we carefully evaluate each item to determine a Movable Price, sales are not guaranteed. The actual sale of items and the total revenue generated are contingent upon market demand and buyer interest. Your final earnings depend on the successful resale of items. (2) Transaction Security: Materialize enhances security by implementing a 30-day hold on sales proceeds, covering returns and maintaining transaction integrity. (3) Indemnification: Buyers are fully responsible for their purchases, allowing partners to confidently sell surplus inventory.</p>
                            </div>
                        </div>
                    )} */}
                </div>
            }
        </div>
    );
};

export default Assessment;