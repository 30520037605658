import './style/Landing.css';

const Landing = () => {
    return (
        <div id="landing">
            <div id="splash-footer" className="container">
                <a href='/privacy' id="privacy-anchor">Privacy Policy</a>
            </div>
        </div>
    );
};

export default Landing;