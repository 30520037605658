const getBrowserData = () => {
    return {
      // User Agent information can indicate browser, OS and device type
      userAgent: navigator.userAgent,
  
      // Language settings
      language: navigator.language || navigator.userLanguage,
  
      // Platform information can indicate OS
      platform: navigator.platform,
  
      // Screen information
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      screenColorDepth: window.screen.colorDepth,
  
      // Viewport information
      viewportSize: `${window.innerWidth}x${window.innerHeight}`,
  
      // Timezone
      timezoneOffset: new Date().getTimezoneOffset(),
  
      // Device memory (if available)
      deviceMemory: navigator.deviceMemory || 'unknown',
  
      // Number of CPU cores (if available)
      hardwareConcurrency: navigator.hardwareConcurrency || 'unknown',
  
      // Network connection information (if available)
      connection: navigator.connection
        ? {
            downlink: navigator.connection.downlink,
            effectiveType: navigator.connection.effectiveType,
            rtt: navigator.connection.rtt,
            saveData: navigator.connection.saveData,
          }
        : {},
  
      // Touch support
      touchSupport: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
  
      // Whether cookies are enabled
      cookiesEnabled: navigator.cookieEnabled,
  
      // Whether Java is enabled (less common now)
      javaEnabled: navigator.javaEnabled ? navigator.javaEnabled() : false,
  
      // Current URL and referrer URL (cleaned up to just show the origin or path as needed)
      currentPage: window.location.href,
      referrer: document.referrer || 'unknown',
  
      // This could be a unique ID generated for the user or session stored in cookie/localStorage
      customUserId: localStorage.getItem('userId') || 'unknown',
  
      // Timestamp
      timestamp: new Date().toISOString(),
    };
  };

  export default getBrowserData;