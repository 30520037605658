
import React, { useEffect, useState, useRef } from 'react';

const FullViewIframe = ({ url }) => {
    const iframeRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const updateDimensions = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const containerHeight = containerRef.current.offsetHeight;
            setDimensions({
                width: containerWidth,
                height: containerHeight
            });
        }
    };

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
      <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'hidden', "padding-top": '68px' }}>
          {url && (
              <iframe
                  ref={iframeRef}
                  src={url}
                  width={dimensions.width}
                  height={dimensions.height + 60}
                  frameBorder="0"
                  allowFullScreen
              ></iframe>
          )}
        </div>
    );
};

export default FullViewIframe;