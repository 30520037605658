import React from "react";

const Hourglass = ({
    size=24, color="#000000", strokeWidth="2"
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path d="M15,12c0-0.542,0.812-1.425,1.599-2.279C18.114,8.075,20,6.026,20,3c0-0.552-0.447-1-1-1H5C4.448,2,4,2.448,4,3
            c0,3.026,1.886,5.075,3.401,6.721C8.188,10.575,9,11.458,9,12c0,0.514-0.777,1.328-1.529,2.115C6.006,15.649,4,17.75,4,21
            c0,0.553,0.448,1,1,1h14c0.553,0,1-0.447,1-1c0-3.25-2.006-5.351-3.471-6.885C15.777,13.328,15,12.514,15,12z M17.897,4
            c-0.236,1.138-0.854,2.115-1.569,3H7.672C6.957,6.115,6.339,5.138,6.103,4H17.897z M6.09,20c0.349-1.908,1.655-3.275,2.827-4.503
            c0.434-0.455,0.854-0.899,1.205-1.355C10.488,16.99,11.59,17,12,17c0.411,0,1.517-0.01,1.879-2.856
            c0.351,0.455,0.771,0.899,1.204,1.353c1.172,1.228,2.479,2.595,2.827,4.503H6.09z"/>
    </svg>
);

export default Hourglass;