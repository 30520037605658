import React from "react";

const Check = ({
    size=24, color="#000000"
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill={color}>
        <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M16.769,9.64l-5,6
            c-0.18,0.216-0.442,0.346-0.723,0.358C11.03,16,11.015,16,11,16c-0.265,0-0.519-0.104-0.707-0.293l-3-3
            c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l2.226,2.226l4.299-5.159c0.354-0.423,0.983-0.482,1.409-0.128
            C17.064,8.585,17.122,9.216,16.769,9.64z"/>
    </svg>
);

export default Check;