import React from 'react';

import './style/User.css';
import Logout from './icon/Logout';

import placeholder from '../media/icon.png';

const User = ({ isAuthenticated, login, user, logout, label }) => {

    return (
        <div id="user">
        {isAuthenticated ? (
            <div id="auth-container">
                <div className="horizontal">
                    <img id="user-image" src={user && user.picture ? user.picture : placeholder} alt={user && user.name ? user.name : null}/>
                    { user && user.name ? (
                        <p className="label">{user.name}</p>
                    ) : user && user.email ? (
                        <p className="label">Email: {user.email}</p>
                    ) : (
                        <p className="label">Unknown</p>
                    )}
                </div>
                <button id="logout-button" onClick={() => logout({ returnTo: window.location.origin })}><Logout size={20} /></button>
            </div>
        ) : (
            <div className="horizontal">
                <p className="label save-progress">{label}</p>
                <button onClick={() => login({
                    authorizationParams: {
                        scope: "openid profile email offline_access"
                    }
                })}>Login or Signup</button>  
            </div>
        )}
        </div>
    )
};

export default User;