import './style/Urgency.css';
import React, {useState} from 'react';
import Bolt from './icon/Bolt';
import Balance from './icon/Balance';
import Hourglass from './icon/Hourglass';
import { formatToCurrency } from '../util/Calculate';

const Urgency = ({urgency, setUrgency, pdfView, rawTotal, highUrgencyTotal, moderateUrgencyTotal, lowUrgencyTotal}) => {
    const [hover, setHover] = useState(false);
    const [hoverOptions, setHoverOptions] = useState(pdfView ? true : false);

    const handleUrgencyHover = () => {
        setHover(!hover);
    };


    const handleUrgencyOptionsHover = () => {
        setHoverOptions(!hoverOptions);
    };

    const handleSwitchUrgency = (value) => {
        setUrgency(value);
        setHoverOptions(false);
        setHover(false);
    }

    const calculateRawTotal = (urgencyTotal) => {
        const lowestAmount = urgencyTotal * .8;
        return formatToCurrency(lowestAmount);
    };

    return (
        <div className="horizontal">
            { !pdfView && (
                <p className="label pdf-view">Urgency</p>
            )}
            { !pdfView && (
                <div id="urgency-dropdown" onMouseEnter={handleUrgencyHover} onMouseLeave={handleUrgencyHover}>
                    {urgency === 0 &&
                        <div className="urgency-images green">
                            <Bolt />
                        </div>
                    }
                    {urgency === 1 &&
                        <div className="urgency-images yellow">
                            <Balance />
                        </div>
                    }
                    {urgency === 2 &&
                        <div className="urgency-images red">
                            <Hourglass />
                        </div>
                    }
                    <div className="vertical">
                        <p className="label">{urgency === 0 ? "High" : urgency === 1 ? "Moderate" : "Low"}</p>
                    </div>
                </div>
            )}
            { (hover || hoverOptions) && !pdfView && (
                <div id="urgency-options-wrapper" onMouseEnter={handleUrgencyOptionsHover} onMouseLeave={handleUrgencyOptionsHover}>
                    <div id="urgency-options">
                        <div className={urgency === 0 ? "horizontal active" : "horizontal"} onClick={() => handleSwitchUrgency(0)}>
                            <div className="urgency-images green">
                                <Bolt />
                            </div>
                            <div className="vertical no-gap">
                                <p className="label">High</p>
                                <p className="description">Typically sells within a month at a minimum of 10% of cost.</p>
                            </div>
                        </div>
                        <div className={urgency === 1 ? "horizontal active" : "horizontal"} onClick={() => handleSwitchUrgency(1)}>
                            <div className="urgency-images yellow">
                                <Balance />
                            </div>
                            <div className="vertical no-gap">
                                <p className="label">Moderate</p>
                                <p className="description">Typically sells within 3 months at a minimum of 20% of cost.</p>                                
                            </div>
                        </div>
                        <div className={urgency === 2 ? "horizontal active" : "horizontal"} onClick={() => handleSwitchUrgency(2)}>
                            <div className="urgency-images red">
                                <Hourglass />
                            </div>
                            <div className="vertical no-gap">
                                <p className="label">Low</p>
                                <p className="description">Typically sells within 6 months at a minimum of 30% of cost.</p>                                
                            </div>
                        </div>
                    </div>
                </div>
            )}
            { pdfView && (
                <div id="urgency-options-wrapper" className="pdf-view">
                    <p className="label pdf-view">Urgency</p>
                    <div id="urgency-options">
                        <div className={urgency === 0 ? "horizontal pdf-card active" : "horizontal pdf-card"}>
                            <div className="urgency-images pdf-view">
                                <Bolt />
                            </div>
                            <div className="vertical no-gap">
                                <p className="label">High</p>
                                <p className="description">Typically sells within a month.</p>
                                <p className="card-total">{calculateRawTotal(highUrgencyTotal)}</p>
                            </div>
                        </div>
                        <div className={urgency === 1 ? "horizontal pdf-card active" : "horizontal pdf-card"}>
                            <div className="urgency-images pdf-view">
                                <Balance />
                            </div>
                            <div className="vertical no-gap">
                                <p className="label">Moderate</p>
                                <p className="description">Typically sells within 3 months.</p>           
                                <p className="card-total">{calculateRawTotal(moderateUrgencyTotal)}</p>                     
                            </div>
                        </div>
                        <div className={urgency === 2 ? "horizontal pdf-card active" : "horizontal pdf-card"}>
                            <div className="urgency-images pdf-view">
                                <Hourglass />
                            </div>
                            <div className="vertical no-gap">
                                <p className="label">Low</p>
                                <p className="description">Typically sells within 6 months.</p>    
                                <p className="card-total">{calculateRawTotal(lowUrgencyTotal)}</p>                            
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Urgency;