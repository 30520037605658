import React, { useCallback, useState } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import './style/FileUpload.css';
import Csv from './icon/Csv';

const FileUpload = ({step, analyzeDataForDollarValues, setData, setStep, setEnabledSteps, sessionId, setSavedFileId, setSavedAssessmentId}) => {
    const [fileName, setFileName] = useState("");

    const sendFileToBackend = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('session_id', sessionId);

        const url = process.env.REACT_APP_API_URL;
        console.log(`ENV URL: ${url}`);

        try {
            const response = await fetch(`${url}/files`, {
                method: 'PUT',
                body: formData,
            });
            const output = await response.json();
            setSavedFileId(output.file_id);
            setSavedAssessmentId(output.assessment_id);
        } catch (error) {
            console.error('Error:', error);
        };
    };

    const handleFileRead = (file) => {
        // persist file
        sendFileToBackend(file);

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const result = Papa.parse(text, { header: true, dynamicTyping: true }).data;
            analyzeDataForDollarValues(result);
            setData(result.map(result => {
                return {
                ...result,
                'manual-type': 'OTS',
                'manual-condition': 'New',
                'manual-material': 'stainless-steel',
                'manual-weight': 0,
                'manual-cost': 0,
                'manual-qty': 0,
                }
            }));
            setStep(1);
            setEnabledSteps([0, 1]);
        };
        reader.readAsText(file);
    };
    
    const onDrop = acceptedFiles => {
        const file = acceptedFiles[0];
        setFileName(file.name);
        handleFileRead(file);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'text/csv' : ['.csv'],
        },
        multiple: false // Ensures only one file can be dropped or selected
    });

    const handleDragOver = (e) => {
        e.preventDefault();

        // add a class to the dropzone when a file is dragged over it
        const dropzone = document.querySelector('.dropzone');
        dropzone.classList.add('dragover');
    }

    const handleDragExit = (e) => {
      e.preventDefault();

      // remove the class from the dropzone when a file is dragged away from it
      const dropzone = document.querySelector('.dropzone');
      dropzone.classList.remove('dragover');
  }
  
    return (
      <div className={step === 0 ? "vertical-card-wrapper" : "hidden"}>
        <h2 className="title">Upload Inventory</h2>
        <div className={fileName ? "card flex-1 uploaded" : "card flex-1"}>
            <div {...getRootProps({ className: 'dropzone' })} onDragOver={handleDragOver} onDragLeave={handleDragExit}>
            <input {...getInputProps()} />
            <div id="csv-icon-wrapper">
              <Csv size={fileName ? 32 : 48}/>
            </div>
            <p className="light-label">{fileName ? fileName : "Drag 'n' drop a CSV file here, or click to select one"}</p>
            {fileName && <button>Replace</button>}
            </div>
        </div>
      </div>
    );
};

export default FileUpload;