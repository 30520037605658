import React from "react";

const Balance = ({
    size=24, color="#000000", strokeWidth="2"
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path d="M22.997,14.748c-0.009-0.171-0.052-0.399-0.103-0.547c-0.053-0.161-0.109-0.277-0.237-0.506l-3.112-5.52
            c-0.355-0.629-1.387-0.629-1.742,0l-3.125,5.542c-0.082,0.148-0.16,0.288-0.226,0.507c-0.048,0.164-0.088,0.437-0.088,0.616
            c0.002,0.183,0.015,0.296,0.049,0.437c0.369,1.611,2.064,3.278,4.261,3.278c2.275,0,4.003-1.348,4.298-3.357
            C22.997,15.027,23.007,14.938,22.997,14.748z M18.674,10.703l2.142,3.797h-4.281L18.674,10.703z M20.901,21h-7.91V7.286
            c0.976-0.318,1.75-1.09,2.069-2.064h5.841c0.553,0,1-0.448,1-1s-0.447-1-1-1h-5.841C14.638,1.934,13.422,1,11.992,1
            c-1.43,0-2.646,0.934-3.068,2.222H3.083c-0.552,0-1,0.448-1,1s0.448,1,1,1h5.841c0.319,0.974,1.092,1.746,2.068,2.064V21H3.083
            c-0.552,0-1,0.447-1,1s0.448,1,1,1h17.819c0.553,0,1-0.447,1-1S21.454,21,20.901,21z M11.992,5.444
            c-0.677,0-1.227-0.548-1.227-1.222S11.315,3,11.992,3c0.677,0,1.228,0.548,1.228,1.222S12.669,5.444,11.992,5.444z M9.607,15.203
            c0.026-0.175,0.036-0.264,0.025-0.455c-0.008-0.164-0.05-0.388-0.101-0.547c-0.053-0.16-0.109-0.276-0.238-0.506l-3.112-5.52
            c-0.354-0.629-1.388-0.629-1.742,0l-3.112,5.521c-0.091,0.161-0.169,0.301-0.236,0.522c-0.052,0.17-0.091,0.442-0.09,0.618
            c0.001,0.202,0.017,0.309,0.048,0.441c0.371,1.61,2.066,3.277,4.261,3.277C7.585,18.556,9.312,17.208,9.607,15.203z M5.31,10.703
            L7.451,14.5H3.169L5.31,10.703z"/>
    </svg>
);

export default Balance;