import './style/ColumnSelect.css';
import React from 'react';
import Info from './icon/Info';

const ColumnSelect = ({
        costType,
        setCostType,
        data,
        setData,
        setStep,
        numberColumns,
        selectedCostColumn,
        setSelectedCostColumn,
        selectedQtyColumn,
        setSelectedQtyColumn}) => {

    const handleCostTypeChange = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setCostType('unit');
        } else {
            setCostType('total');
        }
    };

    const handleCostColumnChange = (event) => {
        const value = event.target.value;
        setSelectedCostColumn(value);

        if (value === 'manual-cost') {
            setData(data.map(row => {
                return {
                    ...row,
                    [value]: 0,
                };
            }));
        };
    };

    const handleQtyColumnChange = (event) => {
        const value = event.target.value;
        setSelectedQtyColumn(value);

        if (value === 'manual-qty') {
            setData(data.map(row => {
                return {
                    ...row,
                    [value]: 0,
                };
            }));
        };
    };

    return (
        <div className="vertical-card-wrapper">
            <div className="horizontal">
                <h2>Identify Columns</h2>
            </div>
            <div className="horizontal-card-wrapper">
                <div className="card flex-1">
                    <div className="horizontal full-width space-between">
                        <p className="label">Cost Column</p>
                        <Info size={18} className="info" />
                    </div>
                    <div className="divider"></div>
                    <div className="horizontal full-width space-between">
                        <p className="label small">Name</p>
                        <div className="select-wrapper">
                            <select value={selectedCostColumn} onChange={(e) => handleCostColumnChange(e)}>
                                {numberColumns.map((column, index) => (
                                    <option key={index} value={column}>{column}&nbsp;</option>
                                ))}
                                <option value="manual-cost">Manually Enter&nbsp;</option>
                            </select>
                        </div>
                    </div>
                    <div className="horizontal full-width space-between">
                        <p className="label small">Type</p>
                        <label className="switch">
                            <input type="checkbox" onChange={(e) => handleCostTypeChange(e)} checked={costType === 'unit'} />
                            <span className="toggle-button-slider">
                                <span className={costType === 'total' ? "text active": "text"}>Total</span>
                                <span className={costType === 'unit' ? "text active": "text"}>Unit</span>
                            </span>
                        </label>
                    </div>
                    {costType !== 'total' && 
                        <div className="vertical row-gap-12">
                            <div className="horizontal m-t-2 full-width space-between">
                                <p className="label">Quantity Column</p>
                                <Info size={18} className="info" />
                            </div>
                            <div className="divider"></div>
                            <div className="horizontal full-width space-between">
                                <p className="label small">Name</p>
                                <div className="select-wrapper">
                                    <select value={selectedQtyColumn} onChange={(e) => handleQtyColumnChange(e)} disabled={costType === 'total'}>
                                        {numberColumns.map((column, index) => (
                                            <option key={index} value={column}>{column}&nbsp;</option>
                                        ))}
                                        <option value="manual-qty">Manually Enter&nbsp;</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="horizontal end">
                <button onClick={() => setStep(2)}>Next</button>
            </div>
        </div>
    );
};

export default ColumnSelect;