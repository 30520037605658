import React from "react";
const Csv = ({size=24, color="#000000"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path d="M21.7070312,7.2929688l-7-7C14.5195312,0.1054688,14.265625,0,14,0H5C3.3457031,0,2,1.3457031,2,3v8H1
            c-0.5523071,0-1,0.4476929-1,1v7c0,0.5523071,0.4476929,1,1,1h1v1c0,1.6542969,1.3457031,3,3,3h14c1.6542969,0,3-1.3457031,3-3
            V8.0078125C22.0019531,7.7446289,21.9003906,7.4858398,21.7070312,7.2929688z M15,3.4140625L18.5859375,7H16
            c-0.5517578,0-1-0.4487305-1-1V3.4140625z M2.3129272,14.2166748c0.2086182-0.3800659,0.4984131-0.6744995,0.8692627-0.8830566
            C3.4307861,13.1923828,3.7086792,13.1154175,4,13.067688c0.1486816-0.024353,0.2949829-0.0540161,0.4547729-0.0540161
            c0.5795288,0,1.0756226,0.1530151,1.4882202,0.4589233c0.4125977,0.3060303,0.6884155,0.7233276,0.8275146,1.2518311h-1.307373
            c-0.0973511-0.2040405-0.2364502-0.359314-0.4172363-0.4659424c-0.1761475-0.1066895-0.3778687-0.1599731-0.6049805-0.1599731
            c-0.1624146,0-0.3059082,0.034668-0.440918,0.0847778c-0.1695557,0.0629272-0.3227539,0.1557617-0.4492188,0.2977295
            c-0.2271729,0.2550049-0.3407593,0.5957031-0.3407593,1.0221558c0,0.4266357,0.1135864,0.767334,0.3407593,1.0223389
            C3.6772461,16.6674805,3.8304443,16.7603149,4,16.8232422C4.1350098,16.8733521,4.2785034,16.90802,4.440918,16.90802
            c0.2271118,0,0.428833-0.0533447,0.6049805-0.1600342c0.1807861-0.1065674,0.3198853-0.2619019,0.4172363-0.4658813h1.307373
            c-0.1390991,0.5285034-0.414917,0.9458008-0.8275146,1.251709c-0.4125977,0.3013916-0.9086914,0.4520874-1.4882202,0.4520874
            c-0.15979,0-0.3060913-0.0290527-0.4547729-0.0529175c-0.2913208-0.0466919-0.5692139-0.1220093-0.8178101-0.2600708
            c-0.3708496-0.2131958-0.6606445-0.5076294-0.8692627-0.8832397C2.1043091,16.4141846,2,15.9854126,2,15.5031738
            C2,15.0211182,2.1043091,14.5922852,2.3129272,14.2166748z M20,21c0,0.5517578-0.4482422,1-1,1H5c-0.5512695,0-1-0.4482422-1-1v-1
            h13c0.5523071,0,1-0.4476929,1-1v-7c0-0.5523071-0.4476929-1-1-1H4V3c0-0.5512695,0.4487305-1,1-1h8v4c0,1.6542969,1.3457031,3,3,3
            h4V21z M9.6165161,16.2893066c-0.0788574-0.0787964-0.1785278-0.1437378-0.2990723-0.1947021
            c-0.1158447-0.0510254-0.2827759-0.1090088-0.5006714-0.1738892c-0.3152466-0.09729-0.5725708-0.1947021-0.7719116-0.2919922
            C7.84552,15.531311,7.6739502,15.3875732,7.5302734,15.1975098c-0.1437378-0.1901245-0.2155762-0.4381104-0.2155762-0.7441406
            c0-0.4542847,0.1645508-0.80896,0.4937134-1.0639648C8.1375732,13.1298218,8.5664062,13,9.0949707,13
            c0.5377197,0,0.9712524,0.1298218,1.300415,0.3894043c0.3291016,0.2550049,0.5053101,0.6119995,0.5285034,1.0709839h-1.286499
            c-0.0092773-0.1577148-0.0672607-0.2805176-0.1738892-0.3685913c-0.1066284-0.0927124-0.2434082-0.1390991-0.4102783-0.1390991
            c-0.1437378,0-0.2596436,0.0394287-0.3477173,0.1182251c-0.0880737,0.0741577-0.1321411,0.1831665-0.1321411,0.3269043
            c0,0.1575928,0.0742188,0.2804565,0.2225342,0.3684692c0.1483765,0.0880737,0.380188,0.1832275,0.6954346,0.2852173
            c0.3152466,0.1065674,0.5702515,0.2085571,0.7649536,0.3059082c0.1993408,0.0973511,0.3709106,0.2387695,0.5145874,0.4241943
            c0.1437378,0.1854858,0.2156372,0.4241943,0.2156372,0.7163086c0,0.2781982-0.0718994,0.5307617-0.2156372,0.7579956
            c-0.1390991,0.2271729-0.3430786,0.407959-0.6119995,0.5423584C9.8900146,17.9328003,9.5724487,18,9.2062378,18
            c-0.3569946,0-0.6768799-0.0579834-0.9597168-0.1738892c-0.2827759-0.1159058-0.5099487-0.2874146-0.6814575-0.5145874
            c-0.1669312-0.2271118-0.2550049-0.5007324-0.2642822-0.8204956h1.265625
            c0.0185547,0.1807861,0.0811768,0.3197632,0.1878052,0.4171753c0.1066284,0.0927124,0.2456665,0.1390991,0.4172363,0.1390991
            c0.1761475,0,0.3152466-0.0394287,0.4172363-0.1182251c0.1019897-0.0834961,0.1530151-0.1970825,0.1530151-0.3407593
            C9.7416992,16.4677734,9.6999512,16.368103,9.6165161,16.2893066z M11.0507812,13.0684204h1.265625l1.210022,3.6856079
        l1.21698-3.6856079h1.258667l-1.7315674,4.8816528h-1.4880981L11.0507812,13.0684204z" shape-rendering="optimizeQuality"/>
    </svg>
);
export default Csv;