import './style/Stepper.css';
import React from 'react';

const Stepper = ({ step, setStep, enabledSteps }) => {
    const handleClickUpload = () => {
        if (enabledSteps.includes(0)) {
            setStep(0);
        };
    };

    const handleClickIdentify = () => {
        if (enabledSteps.includes(1)) {
            setStep(1);
        };
    };

    const handleClickReview = () => {
        if (enabledSteps.includes(2)) {
            setStep(2);
        };
    };

    return (
        <div id="stepper">
            <label className="multiple-toggle">
                <div className={`multiple-button-slider slider-pos-${step}`}>
                    <span onClick={handleClickUpload} className={step === 0 ? "text active" : "text"}>Upload</span>
                    <span onClick={handleClickIdentify} className={!enabledSteps.includes(1) ? "text disabled" : step === 1 ? "text active" : "text"}>Identify</span>
                    <span onClick={handleClickReview} className={!enabledSteps.includes(2) ? "text disabled" : step === 2 ? "text active" : "text"}>Review</span>
                </div>
            </label>
        </div>
    );
};

export default Stepper;