import './style/Table.css';
import React, { useState, useEffect } from 'react';
import Trash from './icon/Trash.js';
import ChevronRight from './icon/ChevronRight.js';
import { getValueForRowFormatted, formatToCurrency } from '../util/Calculate.js';

const Table = ({
        data,
        setData,
        numberColumns,
        selectedCostColumn,
        selectedQtyColumn,
        urgency,
        setTotal,
        costType,
        pdfView,
        setActionsLeft,
        setRawCost,
        setRawTotal,
        otsCotsCostTotal,
        setOtsCotsCostTotal,
        customCostTotal,
        setCustomCostTotal,
        deletedCotsOtsColumns,
        setDeletedCotsOtsColumns,
        deletedCustomColumns,
        setDeletedCustomColumns,
        setRawOtsCotsCostTotal,
        setRawCustomCostTotal
    }) => {
    const [hasCustomRows, setHasCustomRows] = useState(false);
    const [cotsOtsInputTableVisible, setCotsOtsInputTableVisible] = useState(false);
    const [customInputTableVisible, setCustomInputTableVisible] = useState(false);

    useEffect(() => {
        // get count of elements with needs-info class
        const needsInfo = document.querySelectorAll('.needs-info').length;
        setActionsLeft(needsInfo);
    }, []);

    useEffect(() => {
        const modifiedData = data.map(row => {
            if (selectedCostColumn !== 'manual-cost' && row[selectedCostColumn]) {
                row['manual-cost'] = Number(row[selectedCostColumn]?.toString().replace(/[^0-9.]+/g, ''));
            }

            if (selectedCostColumn !== 'manual-qty' && row[selectedQtyColumn]) {
                row['manual-qty'] = Number(row[selectedQtyColumn]?.toString().replace(/[^0-9.]+/g, ''));
            }

            return {
                ...row,
                'Minimum Price': getValueForRowFormatted(row, selectedCostColumn, selectedQtyColumn, costType, urgency),
            };
        });

        // add up minimum price for each row
        let total = 0;

        modifiedData.forEach(row => {
            total += Number(row['Minimum Price']?.replace(/\$|,/g, ''));
        });

        setRawTotal(total);

        setTotal(formatToCurrency(total * .8));

        setData(modifiedData);
    }, [numberColumns, urgency, selectedCostColumn, selectedQtyColumn, costType]);

    useEffect(() => {
        let total = 0;
        let otsCotsCostTotal = 0;
        let customCostTotal = 0;
        data.forEach(row => {
            if (!row['Minimum Price']) return;
            total += Number(row['Minimum Price']?.replace(/\$|,/g, ''));
            if (row['manual-type'] === 'custom') {
                customCostTotal += Number(row['Minimum Price']?.replace(/\$|,/g, ''));
            } else {
                otsCotsCostTotal += Number(row['Minimum Price']?.replace(/\$|,/g, ''));
            }
        });
        setTotal(formatToCurrency(total * .8));

        setRawOtsCotsCostTotal(otsCotsCostTotal);
        setOtsCotsCostTotal(formatToCurrency(otsCotsCostTotal));

        setRawCustomCostTotal(customCostTotal);
        setCustomCostTotal(formatToCurrency(customCostTotal));

        if (data.some(row => row['manual-type'] === 'custom')) {
            setHasCustomRows(true);
        } else {
            setHasCustomRows(false);
        }
    }, [data]);

    const handleCostInputChange = (index, event) => {
        const newData = data.map((row, i) => {
            if (index === i) {
                row[selectedCostColumn] = event.target.value;
                row['manual-cost'] = event.target.value;
                row['Minimum Price'] = getValueForRowFormatted(row, selectedCostColumn, selectedQtyColumn, costType, urgency);
            }
            return row;
        });
        setData(newData);

        if (event.target.value !== '') {
            // remove needs-info class from event target
            event.target.classList.remove('needs-info');
        }
    };

    const handleQtyInputChange = (index, event) => {
        const newData = data.map((row, i) => {
            if (index === i) {
                row['manual-qty'] = event.target.value;
                row[selectedQtyColumn] = event.target.value;
                row['Minimum Price'] = getValueForRowFormatted(row, selectedCostColumn, selectedQtyColumn, costType, urgency);
            }
            return row;
        });
        setData(newData);

        if (event.target.value !== '') {
            // remove needs-info class from event target
            event.target.classList.remove('needs-info');
        }
    };

    const handleWeightInputChange = (index, event) => {
        const newData = data.map((row, i) => {
            if (index === i) {
                row['manual-weight'] = event.target.value;
                row['Minimum Price'] = getValueForRowFormatted(row, selectedCostColumn, selectedQtyColumn, costType, urgency);
            }
            return row;
        });
        setData(newData);

        if (event.target.value !== '') {
            // remove needs-info class from event target
            event.target.classList.remove('needs-info');
        }
    };

    const handleTypeChange = (event, row) => {
        const newData = data.map((dataRow) => {
            if (dataRow === row) {
                const type = event.target.value;
                dataRow['manual-type'] = type;
                dataRow['Minimum Price'] = getValueForRowFormatted(dataRow, selectedCostColumn, selectedQtyColumn, costType, urgency);
            }
            return dataRow;
        });
        setData(newData);
    };

    const handleMaterialChange = (event, row) => {
        const newData = data.map((dataRow) => {
            if (dataRow === row) {
                dataRow['manual-material'] = event.target.value;
                dataRow['Minimum Price'] = getValueForRowFormatted(dataRow, selectedCostColumn, selectedQtyColumn, costType, urgency);
            }
            return dataRow;
        });
        setData(newData);
    };

    const handleConditionChange = (event, row) => {
        const newData = data.map((dataRow) => {
            if (dataRow === row) {
                dataRow['manual-condition'] = event.target.value;
            }
            return dataRow;
        });
        setData(newData);
    };

    const handleDeleteCotsOtsColumn = (e, index) => {
        Object.keys(data[0]).forEach((key, i) => {
            if (i === index) {
                setDeletedCotsOtsColumns([...deletedCotsOtsColumns, {
                    index: i,
                    name: key,
                }]);
            }
        });
    };

    const handleDeleteCustomColumn = (e, index) => {
        Object.keys(data[0]).forEach((key, i) => {
            if (i === index) {
                setDeletedCustomColumns([...deletedCustomColumns, {
                    index: i,
                    name: key,
                }]);
            }
        });
    };

    const rows = data.map((row, index) => (
        <tr key={index}>
            {row['manual-type'] !== 'custom' && Object.keys(row).map((key, i) => {
                if (key === 'Minimum Price' ||
                    key === 'manual-material' ||
                    key === 'manual-weight' ||
                    key === 'manual-type' ||
                    key === 'manual-condition' ||
                    key === 'manual-cost' ||
                    key === 'manual-qty' || 
                    deletedCotsOtsColumns.find(column => column.name === key && column.index === i)
                ) return;

                let shortText = row[key];
                let max = pdfView ? 20 : 48;
                if (shortText?.length > max) {
                    shortText = shortText?.substring(0, (max / 2)) + '...' + shortText?.substring(shortText?.length - (max / 2), shortText?.length);
                }
                return (
                    <td key={i} className={pdfView && "no-width"}>{shortText}</td>
                );
            })}
        </tr>
    ));

    const manualRows = data.map((row, index) => (
        <tr key={index}>
            {row['manual-type'] !== 'custom' && Object.keys(row).map((key, i) => {
                if (key === 'manual-type' && !pdfView) {
                    return (
                        <td>
                            <div className="select-wrapper on-table">
                                <select onChange={(e) => handleTypeChange(e, row)} value={row['manual-type']}>
                                    <option value="ots">OTS&nbsp;</option>
                                    <option value="cots">Custom OTS&nbsp;</option>
                                    <option value="custom">Custom&nbsp;</option>
                                </select>
                            </div>
                        </td>
                    );
                } else if (key === 'manual-condition' && !pdfView) {
                    return (
                        <td>
                            <div className="select-wrapper on-table">
                                <select onChange={(e) => handleConditionChange(e, row)} value={row['manual-condition']}>
                                    <option value="new">New&nbsp;</option>
                                    <option value="opened">Opened&nbsp;</option>
                                    <option value="used">Used&nbsp;</option>
                                </select>
                            </div>
                        </td>
                    );
                } else if (key === 'manual-cost') {
                    return (
                        <td key={i}>
                            {!pdfView ?
                                <input type="number" value={row[key]} className={row[key] === 0 &&  "needs-info"} onChange={(e) => handleCostInputChange(index, e)}/>
                                : `$${Number(row[key] * (urgency === 2 ? 1 : urgency === 1 ? 0.82 : 0.64)).toFixed(2)}`
                            }
                        </td>
                    );        
                } else if (key === 'manual-qty') {
                    if (costType !== 'total') {
                        return (
                            <td key={i} className="quantity-data">
                                {!pdfView ?
                                    <input type="number" value={row[key]} className={row[key] === 0 &&  "needs-info"} onChange={(e) => handleQtyInputChange(index, e)}/>
                                    : row[key]
                                }
                            </td>
                        );
                    }
                }
            })}
            {row['manual-type'] !== 'custom' && <td key={index} className={'right-align'}>{row['Minimum Price']}</td>}
        </tr>
    ));

    const customDataRows = data.map((row, index) => (
        <tr key={index}>
            {row['manual-type'] === 'custom' && Object.keys(row).map((key, i) => {
                if (key === 'Minimum Price' ||
                    numberColumns.includes(key) ||
                    key === 'manual-condition' ||
                    key === 'manual-cost' ||
                    key === 'manual-qty' || 
                    key === 'manual-type' ||
                    key === 'manual-material' ||
                    key === 'manual-weight' || 
                    deletedCustomColumns.find(column => column.name === key && column.index === i)
                ) return;

                let shortText = row[key];
                let max = pdfView ? 24 : 64;
                if (shortText?.length > max) {
                    shortText = shortText?.substring(0, (max / 2)) + '...' + shortText?.substring(shortText?.length - (max / 2), shortText?.length);
                }
                return (
                    <td key={i} className={pdfView && "no-width"}>{shortText}</td>
                );
            })}
        </tr>
    ));

    const customInputRows = data.map((row, index) => (
        <tr key={index}>
            {row['manual-type'] === 'custom' && Object.keys(row).map((key, i) => {
                if (key === 'manual-type' && !pdfView) {
                    return (
                        <td>
                            <div className="select-wrapper on-table">
                                <select onChange={(e) => handleTypeChange(e, row)} value={row['manual-type']}>
                                    <option value="ots">OTS&nbsp;</option>
                                    <option value="cots">Custom OTS&nbsp;</option>
                                    <option value="custom">Custom&nbsp;</option>
                                </select>
                            </div>
                        </td>
                    );
                } else if (key === 'manual-material') {
                    return (
                        <td>
                            {!pdfView ?
                                <div className="select-wrapper on-table">
                                    <select onChange={(e) => handleMaterialChange(e, row)} value={row['manual-material']}>
                                        <option value="stainless-steel">Stainless Steel&nbsp;</option>
                                        <option value="aluminum">Aluminum&nbsp;</option>
                                        <option value="steel">Steel&nbsp;</option>
                                        <option value="copper">Copper&nbsp;</option>
                                    </select>
                                </div>
                                : row[key]
                            }
                        </td>
                    );
                } else if (key === 'manual-weight') {
                    return (
                        <td key={i}>
                            {!pdfView ?
                                <input type="number" value={row[key]} className={row[key] === 0 &&  "needs-info"} onChange={(e) => handleWeightInputChange(index, e)}/>
                                : row[key]
                            }
                        </td>
                    );
                }
            })}
            {row['manual-type'] === 'custom' &&  <td key={index} className={'right-align'}>{row['Minimum Price']}</td>}
        </tr>
    ));

    const toggleCotsOtsInputTable = () => {
        setCotsOtsInputTableVisible(!cotsOtsInputTableVisible);
    };

    const toggleCustomInputTable = () => {
        setCustomInputTableVisible(!customInputTableVisible);
    };

    return (
        <div className="vertical">
            <p className="label for-table">Items</p>
            <div id="table-wrapper" className="horizontal">
                <div id="ots-cots-data-table">
                    <table className={pdfView ? "pdf-view" : ""}>
                        <thead>
                            <tr>
                                {data[0] && Object.keys(data[0]).map((header, index) => {
                                    if (header === 'Minimum Price' ||
                                        header === 'manual-material' ||
                                        header === 'manual-weight' ||
                                        header === 'manual-type' ||
                                        header === 'manual-condition' ||
                                        header === 'manual-cost' ||
                                        header === 'manual-qty' ||
                                        deletedCotsOtsColumns.find(column => column.name === header && column.index === index)
                                    ) return;

                                    return (
                                        <th className="th-with-button" key={index}>
                                            <div className="th-wrapper">
                                                <p>{header}</p>
                                                <button className="delete-column" onClick={(e) => handleDeleteCotsOtsColumn(e, index)}>
                                                    <Trash/>
                                                </button>
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
                <div className="expand-wrapper">
                    <div className={!cotsOtsInputTableVisible ? "expand-control collapsed" : "expand-control"} onClick={toggleCotsOtsInputTable}><ChevronRight /></div>
                </div>
                <div id="ots-cots-input-table" className={cotsOtsInputTableVisible && 'collapsed'}>
                    <table className={pdfView ? "pdf-view" : ""}>
                        <thead>
                            <tr>
                                {data[0] && Object.keys(data[0]).map((header, index) => {
                                    if ((header === 'manual-type' || header === 'manual-condition') && !pdfView) {
                                        return (
                                            <th key={index}>
                                                <div className="th-wrapper">
                                                    <p>{header === 'manual-type' ? 'Type' : 'Condition'}</p>
                                                </div>
                                            </th>
                                        );
                                    } else if (header === 'manual-cost') {
                                        return (
                                            <th key={index}>
                                                <div className="th-wrapper">
                                                    {!pdfView ? (
                                                        <p>{costType === 'unit' ? "Cost Per Unit" : "Total Cost"}</p>
                                                    ): (
                                                        <p>{costType === 'unit' ? "Movable Price (Unit)" : "Total Cost"}</p>
                                                    )}
                                                </div>
                                            </th>
                                        );
                                    } else if (header === 'manual-qty') {
                                        if (costType !== 'total' ) {
                                            return (
                                                <th key={index} className="quantity-header">
                                                    <div className="th-wrapper">
                                                        <p>Quantity</p>
                                                    </div>
                                                </th>
                                            );
                                        };
                                    }
                                })}
                                <th key={'mh'}>
                                    <div className="th-wrapper">
                                        <p>Total Value If Sold</p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {manualRows}
                        </tbody>
                    </table>
                </div>
            </div>
            {!pdfView && <div className="horizontal end">
                <p className="sub-total-info">Value Captured:</p>
                <p className="label sub-total">{otsCotsCostTotal}</p>
            </div>}
            { hasCustomRows && (
                <p id="custom-table-label" className="label for-table">Custom</p>
            )}
            { hasCustomRows && (
                <div id="custom-table-wrapper" className="horizontal">
                    <div id="custom-data-table">
                        <table className={pdfView ? "pdf-view" : ""}>
                            <thead>
                                <tr>
                                    {data[0] && Object.keys(data[0]).map((header, index) => {
                                        if (header === 'Minimum Price' ||
                                            numberColumns.includes(header) ||
                                            header === 'manual-condition' ||
                                            header === 'manual-cost' ||
                                            header === 'manual-qty' ||
                                            header === 'manual-type' ||
                                            header === 'manual-condition' ||
                                            header === 'manual-material' ||
                                            header === 'manual-weight' || 
                                            deletedCustomColumns.find(column => column.name === header && column.index === index)
                                        ) return;

                                        return (
                                            <th className="th-with-button" key={index}>
                                                <div className="th-wrapper">
                                                    <p>{header}</p>
                                                    <button className="delete-column" onClick={(e) => handleDeleteCustomColumn(e, index)}>
                                                        <Trash/>
                                                    </button>
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {customDataRows}
                            </tbody>
                        </table>
                    </div>
                    <div className="expand-wrapper">
                        <div className={!customInputTableVisible ? "expand-control collapsed" : "expand-control"} onClick={toggleCustomInputTable}><ChevronRight /></div>
                    </div>
                    <div id="custom-input-table" className={customInputTableVisible && 'collapsed'}>
                        <table className={pdfView ? "pdf-view" : ""}>
                            <thead>
                                <tr>
                                    {data[0] && Object.keys(data[0]).map((header, index) => {
                                        if (header === 'manual-type' && !pdfView) {
                                            return (
                                                <th key={index}>
                                                    <div className="th-wrapper">
                                                        <p>{header === 'manual-type' ? 'Type' : 'Condition'}</p>
                                                    </div>
                                                </th>
                                            );
                                        } else if (header === 'manual-material' || header === 'manual-weight') {
                                            return (
                                                <th key={index}>
                                                    <div className="th-wrapper">
                                                        <p>{header === 'manual-material' ? 'Material' : 'Weight (lb)'}</p>
                                                    </div>
                                                </th>
                                            );
                                        }
                                    })}
                                    <th key='mh-custom'>
                                        <div className="th-wrapper">
                                            <p>Recycling Return</p>
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {customInputRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {(hasCustomRows && !pdfView) && (
                <div className="horizontal end">
                    <p className="sub-total-info">Recycling Subtotal:</p>
                    <p className="label sub-total">{customCostTotal}</p>
                </div>
            )}
        </div>
    );
};

export default Table;